import dayjs, { type DateValue, type UnitType, type Dayjs } from './dayjs'


// This is a helper for testing
let currentTime: Dayjs
try {
  if (__CLIENT__ && window?.location?.search) {
    const params = new URLSearchParams(window.location.search)

    if (params.has('currentTime')) {
      currentTime = dayjs(params.get('currentTime'))

      if (!currentTime.isValid()) {
        currentTime = null
      }
    }
  }
}
catch (error) {
  console.error('Error setting date')
}


// returns difference from past to now
export const getElapsedTime = (date: DateValue, unit: UnitType = 'hours', from: DateValue = currentTime) => {
  return dayjs(from).diff(dayjs(date), unit)
}
