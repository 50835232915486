import dayjs, { Dayjs } from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import duration from 'dayjs/plugin/duration'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import 'dayjs/locale/en'


dayjs.extend(localeData)
dayjs.extend(localizedFormat)
dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.locale('en')

export type DateValue = Dayjs | string | Date | number

export type { Dayjs, UnitType } from 'dayjs'
export default dayjs
